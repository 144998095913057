<template>
  <div
    class="pt-[4rem] bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF]"
    v-if="program"
  >
    <div class="max-w-7xl m-auto">
      <div
        class="flex flex-col lg:flex-row-reverse justify-center lg:justify-around items-center lg:pb-8"
      >
        <div class="mt-[3rem] ssm:p-[1rem]">
          <img
            :src="program.mobile_banner"
            alt="programmMob"
            class="lg:hidden"
          />
          <img
            :src="program.banner"
            alt="programmDesk"
            class="hidden lg:block"
          />
        </div>
        <div class="self-start md:self-center ssm:ml-4 ml-12 ssm:mt-4 mt-8">
          <h1 class="text-[#EC027C] font-bold text-[1.2rem]">
            Online {{ program.name }}
          </h1>
          <h1 class="text-[#18479E] text-3xl lg:text-4xl lg:font-semibold">
            {{ stripHtmlTags(program.focus) }}
          </h1>
          <div class="py-4 lg:py-8">
            <div class="flex items-center gap-x-4">
              <div class="flex items-center gap-x-3">
                <div>
                  <img src="@/assets/programm/NAAC_A+.webp" alt="NAAC_A+" />
                </div>
                <div class="text-sm lg:text-lg font-bold">
                  NAAC A+ accredited
                </div>
              </div>
              <div class="flex items-center gap-x-3">
                <div>
                  <img
                    src="@/assets/programm/UGC_India_Logo.webp"
                    alt="ugc_india"
                  />
                </div>
                <div class="text-sm lg:text-lg font-bold">UGC-entitled</div>
              </div>
            </div>
          </div>
          <div class="flex gap-x-8 lg:mb-4">
            <div class="flex items-center gap-x-4">
              <div>
                <img src="@/assets/programm/lastDate.png" alt="lastDate" />
              </div>
              <div class="text-xs text-black font-semibold lg:hidden">
                Limited <br />
                seats available!
              </div>
              <div class="text-base text-black font-semibold hidden lg:block">
                Limited seats available!
              </div>
            </div>
            <div class="flex items-center gap-x-4">
              <div>
                <img src="@/assets/programm/limitedSeat.png" alt="lastDate" />
              </div>
              <div class="text-xs text-black font-semibold lg:hidden">
                Admission Open
              </div>
              <div class="text-base text-black font-semibold hidden lg:block">
                Admission Open
              </div>
            </div>
          </div>
          <div class="flex ssm:gap-x-[.8rem] gap-x-[2rem] py-4 mb-4">
            <a href="https://apply.shardaonline.ac.in/" target="_blank">
              <div
                class="rounded-full border border-[#EC027C] w-[8rem] lg:w-[10rem] cursor-pointer text-center py-2 text-[#EC027C] bg-white lg:font-bold"
              >
                Apply Now
              </div>
            </a>
            <a :href="program.brochures" target="_blank">
              <div
                class="rounded-full border border-[#EC027C] w-[10rem] text-center py-2 text-[#EC027C] bg-white lg:hidden cursor-pointer"
              >
                Download Brochure
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bannerView",
  props: ["program"],
  data() {
    return {
      programOverView: "",
    };
  },
  methods: {
    stripHtmlTags(input) {
      if (typeof input === "string") {
        this.programOverView = input.replace(/<\/?[^>]+(>|$)/g, "");
      } else {
        this.programOverView = "";
      }
      return this.programOverView
    },
  },
};
</script>

<style scoped></style>
