<template lang="">
    <div>
      <!-- Modal -->
  
      <div class="text-[13px]" v-if="showForm">
        <h2 class="text-black font-bold text-2xl mb-3">
          Secure Your Free Career Counseling Session Today!
        </h2>
        <div>
          <form
            class="space-y-3 p-4 font-normal"
            @submit="$emit('closeWidget')"
            @submit.prevent="enquireSubmit"
          >
            <input
              type="text"
              name="name"
              id="name"
              autocomplete="off"
              class="p-2 rounded-md w-full border border-[#ccc]"
              maxlength="255"
              minlength="1"
              placeholder="Name *"
              required
              v-model="name"
              v-on:input="validateInput"
            />
            <!-- <input
              type="email"
              placeholder="Enter Email Address *"
              id="email"
              class="p-2 rounded-md w-full border border-[#ccc]"
              required
            /> -->
            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-center">
                <select
                  v-model="selectedCountryCode"
                  name="country_id"
                  class="text-sm w-[83px] bg-white p-2 border border-[#ccc] rounded-md"
                  required
                >
                  <option
                    v-for="country in countryCodes"
                    :key="country.code"
                    :value="country.code"
                  >
                    {{
                      selectedCountryCode === country.code
                        ? country.code
                        : `${country.code} ${country.name}`
                    }}
                  </option>
                </select>
                <input
                  type="tel"
                  placeholder="Mobile Number *"
                  id="phone"
                  autocomplete="off"
                  maxlength="10"
                  v-model="phoneNumber"
                  pattern="[0-9]*"
                  class="p-2 rounded-md w-full border border-[#ccc] pr-12"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  ref="phone"
                  required
                />
              </div>
            </div>
  
            <button
              type="submit"
              class="w-full text-white font-semibold bg-[#0CB1EF] hover:bg-[#ca9216] rounded-lg px-4 py-2 text-center"
            >
              Submit
            </button>
          </form>
          <span
            v-if="leadstatus"
            class="text-red-500 text-xs text-left pl-0 font-bold pt-2"
            >{{ leadMessage }}</span
          >
        </div>
      </div>
      <!-- <div v-if="!showForm">
        <div class="flex flex-col justify-center gap-20 text-center">
          <div>
            <p>
              Thank you! Your Enquiry has been submitted successfully. Our
              admissions team will get in touch with you soon. A verification
              email has been sent to your registered email ID. Please ignore if
              already verified.
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    name: "EnquireView",
  
    props: ["widget_prop_id"],
  
    data: () => ({
      showForm: true,
      utmSource: "",
      utmSourceCampaign: "",
      utmSourceMedium: "",
      utmAdgroupid: "",
      utmCampaignid: "",
      utmCreativeid: "",
      utmDevice: "",
      utmKeyword: "",
      utmMatchtype: "",
      utmNetwork: "",
      utmPlacement: "",
      utmGclid: "",
      widget_id: "",
      activeTab: "tab-1",
      errorStatus: false,
      message: "",
      phoneNumber: "",
  
      selectedCountryCode: "+91-",
      countryCodes: [
        {
          code: "+93-",
          name: "Afghanistan",
        },
  
        {
          code: "+355-",
          name: "Albania",
        },
  
        {
          code: "+213-",
          name: "Algeria",
        },
  
        {
          code: "+1684-",
          name: "American Samoa",
        },
  
        {
          code: "+376-",
          name: "Andorra",
        },
  
        {
          code: "+244-",
          name: "Angola",
        },
  
        {
          code: "+1264-",
          name: "Anguilla",
        },
  
        {
          code: "+1268-",
          name: "Antigua and Barbuda",
        },
  
        {
          code: "+54-",
          name: "Argentina",
        },
  
        {
          code: "+374-",
          name: "Armenia",
        },
  
        {
          code: "+297-",
          name: "Aruba",
        },
  
        {
          code: "+61-",
          name: "Australia",
        },
  
        {
          code: "+43-",
          name: "Austria",
        },
  
        {
          code: "+994-",
          name: "Azerbaijan",
        },
  
        {
          code: "+1242-",
          name: "Bahamas",
        },
  
        {
          code: "+973-",
          name: "Bahrain",
        },
  
        {
          code: "+880-",
          name: "Bangladesh",
        },
  
        {
          code: "+1246-",
          name: "Barbados",
        },
  
        {
          code: "+375-",
          name: "elarus",
        },
  
        {
          code: "+32-",
          name: "Belgium",
        },
  
        {
          code: "+501-",
          name: "Belize",
        },
  
        {
          code: "+229-",
          name: "Benin",
        },
  
        {
          code: "+1441-",
          name: "Bermuda",
        },
  
        {
          code: "+975-",
          name: "Bhutan",
        },
  
        {
          code: "+591-",
          name: "Bolivia",
        },
  
        {
          code: "+387-",
          name: "Bosnia and Herzegovina",
        },
  
        {
          code: "+267-",
          name: "Botswana",
        },
  
        {
          code: "+55-",
          name: "Brazil",
        },
  
        {
          code: "+246-",
          name: "British Indian Ocean Territory",
        },
  
        {
          code: "+1284-",
          name: "British Virgin Islands",
        },
  
        {
          code: "+673-",
          name: "Brunei Darussalam",
        },
  
        {
          code: "+359-",
          name: "Bulgaria",
        },
  
        {
          code: "+226-",
          name: "Burkina Faso",
        },
  
        {
          code: "+257-",
          name: "Burundi",
        },
  
        {
          code: "+855-",
          name: "Cambodia",
        },
  
        {
          code: "+237-",
          name: "Cameroon",
        },
  
        {
          code: "+1-",
          name: "Canada",
        },
  
        {
          code: "+238-",
          name: "Cape Verde",
        },
  
        {
          code: "+1345-",
          name: "Cayman Islands",
        },
  
        {
          code: "+236-",
          name: "Central African Republic",
        },
  
        {
          code: "+235-",
          name: "Chad",
        },
  
        {
          code: "+56-",
          name: "Chile",
        },
  
        {
          code: "+86-",
          name: "China",
        },
  
        {
          code: "+61-",
          name: "Cocos Islands",
        },
  
        {
          code: "+57-",
          name: "Colombia",
        },
  
        {
          code: "+269-",
          name: "Comoros",
        },
  
        {
          code: "+682-",
          name: "Cook Islands",
        },
  
        {
          code: "+506-",
          name: "Costa Rica",
        },
  
        {
          code: "+385-",
          name: "Croatia",
        },
  
        {
          code: "+53-",
          name: "Cuba",
        },
  
        {
          code: "+357-",
          name: "Cyprus",
        },
  
        {
          code: "+420-",
          name: "Czech Republic",
        },
  
        {
          code: "+243-",
          name: "Democratic Republic of The Congo",
        },
  
        {
          code: "+45-",
          name: "Denmark",
        },
  
        {
          code: "+253-",
          name: "Djibouti",
        },
  
        {
          code: "+1767-",
          name: "Dominica",
        },
  
        {
          code: "+1809-",
          name: "Dominican Republic",
        },
  
        {
          code: "+1829-",
          name: "Dominican Republic",
        },
  
        {
          code: "+1849-",
          name: "Dominican Republic",
        },
  
        {
          code: "+670-",
          name: "East Timor",
        },
  
        {
          code: "+593-",
          name: "Ecuador",
        },
  
        {
          code: "+20-",
          name: "Egypt",
        },
  
        {
          code: "+503-",
          name: "El Salvador",
        },
  
        {
          code: "+240-",
          name: "Equatorial Guinea",
        },
  
        {
          code: "+291-",
          name: "Eritrea",
        },
  
        {
          code: "+372-",
          name: "Estonia",
        },
  
        {
          code: "+251-",
          name: "Ethiopia",
        },
  
        {
          code: "+500-",
          name: "Falkland Islands",
        },
  
        {
          code: "+298-",
          name: "Faroe Islands",
        },
  
        {
          code: "+679-",
          name: "Fiji",
        },
  
        {
          code: "+358-",
          name: "Finland",
        },
  
        {
          code: "+33-",
          name: "France",
        },
  
        {
          code: "+594-",
          name: "French Guiana",
        },
  
        {
          code: "+689-",
          name: "French Polynesia",
        },
  
        {
          code: "+241-",
          name: "Gabon",
        },
  
        {
          code: "+220-",
          name: "Gambia",
        },
  
        {
          code: "+995-",
          name: "Georgia",
        },
  
        {
          code: "+49-",
          name: "Germany",
        },
  
        {
          code: "+233-",
          name: "Ghana",
        },
  
        {
          code: "+350-",
          name: "Gibraltar",
        },
  
        {
          code: "+30-",
          name: "Greece",
        },
  
        {
          code: "+299-",
          name: "Greenland",
        },
  
        {
          code: "+1473-",
          name: "Grenada",
        },
  
        {
          code: "+1671-",
          name: "Guam",
        },
  
        {
          code: "+502-",
          name: "Guatemala",
        },
  
        {
          code: "+441481-",
          name: "Guernsey",
        },
  
        {
          code: "+224-",
          name: "Guinea",
        },
  
        {
          code: "+245-",
          name: "Guinea-Bissau",
        },
  
        {
          code: "+592-",
          name: "Guyana",
        },
  
        {
          code: "+509-",
          name: "Haiti",
        },
  
        {
          code: "+504-",
          name: "Honduras",
        },
  
        {
          code: "+852-",
          name: "Hong Kong",
        },
  
        {
          code: "+36-",
          name: "Hungary",
        },
  
        {
          code: "+354-",
          name: "Iceland",
        },
  
        {
          code: "+91-",
          name: "India",
        },
  
        {
          code: "+62-",
          name: "Indonesia",
        },
  
        {
          code: "+98-",
          name: "Iran",
        },
  
        {
          code: "+964-",
          name: "Iraq",
        },
  
        {
          code: "+353-",
          name: "Ireland",
        },
  
        {
          code: "+441624-",
          name: "Isle of Man",
        },
  
        {
          code: "+972-",
          name: "Israel",
        },
  
        {
          code: "+39-",
          name: "Italy",
        },
  
        {
          code: "+225-",
          name: "Ivory Coast",
        },
  
        {
          code: "+1876-",
          name: "Jamaica",
        },
  
        {
          code: "+81-",
          name: "Japan",
        },
  
        {
          code: "+44153-",
          name: "Jersey",
        },
  
        {
          code: "+962-",
          name: "Jordan",
        },
  
        {
          code: "+254-",
          name: "Kenya",
        },
  
        {
          code: "+686-",
          name: " Kiribati",
        },
  
        {
          code: "+850-",
          name: "Korea (North)",
        },
  
        {
          code: "+82-",
          name: " Korea (South)",
        },
  
        {
          code: "+383-",
          name: "Kosovo",
        },
  
        {
          code: "+965-",
          name: "Kuwait",
        },
  
        {
          code: "+996-",
          name: "Kyrgyzstan",
        },
  
        {
          code: "+856-",
          name: "Laos",
        },
  
        {
          code: "+371-",
          name: "Latvia",
        },
  
        {
          code: "+961-",
          name: "Lebanon",
        },
  
        {
          code: "+266-",
          name: "Lesotho",
        },
  
        {
          code: "+231-",
          name: "Liberia",
        },
  
        {
          code: "+218-",
          name: " Libya",
        },
  
        {
          code: "+423-",
          name: "Liechtenstein",
        },
  
        {
          code: "+370-",
          name: "Lithuania",
        },
  
        {
          code: "+352-",
          name: "Luxembourg",
        },
  
        {
          code: "+853-",
          name: "Macau",
        },
  
        {
          code: "+389-",
          name: " Macedonia",
        },
  
        {
          code: "+261-",
          name: "Madagascar",
        },
  
        {
          code: "+265-",
          name: " Malawi",
        },
  
        {
          code: "+60-",
          name: "Malaysia",
        },
  
        {
          code: "+960-",
          name: "Maldives",
        },
  
        {
          code: "+223-",
          name: "Mali",
        },
  
        {
          code: "+356-",
          name: " Malta",
        },
  
        {
          code: "+692-",
          name: " Marshall Islands",
        },
  
        {
          code: "+596-",
          name: "Martinique",
        },
  
        {
          code: "+222-",
          name: "Mauritania",
        },
  
        {
          code: "+230-",
          name: "Mauritius",
        },
  
        {
          code: "+52-",
          name: "Mexico",
        },
  
        {
          code: "+691-",
          name: " Micronesia",
        },
  
        {
          code: "+373-",
          name: " Moldova",
        },
  
        {
          code: "+377-",
          name: "Monaco",
        },
  
        {
          code: "+976-",
          name: " Mongolia",
        },
  
        {
          code: "+382-",
          name: "Montenegro",
        },
  
        {
          code: "+1664-",
          name: "Montserrat",
        },
  
        {
          code: "+212-",
          name: " Morocco",
        },
  
        {
          code: "+258-",
          name: "Mozambique",
        },
  
        {
          code: "+95-",
          name: " Myanmar",
        },
  
        {
          code: "+264-",
          name: "Namibia",
        },
  
        {
          code: "+674-",
          name: "Nauru",
        },
  
        {
          code: "+977-",
          name: " Nepal",
        },
  
        {
          code: "+31-",
          name: " Netherlands",
        },
  
        {
          code: "+599-",
          name: "Netherlands Antilles",
        },
  
        {
          code: "+687-",
          name: " New Caledonia",
        },
  
        {
          code: "+64-",
          name: " New Zealand",
        },
  
        {
          code: "+505-",
          name: "Nicaragua",
        },
  
        {
          code: "+227-",
          name: "Niger",
        },
  
        {
          code: "+234-",
          name: " Nigeria",
        },
  
        {
          code: "+683-",
          name: " Niue",
        },
  
        {
          code: "+672-",
          name: " Norfolk Island",
        },
  
        {
          code: "+1670-",
          name: "Northern Mariana Islands",
        },
  
        {
          code: "+968-",
          name: " Oman",
        },
  
        {
          code: "+92-",
          name: "  Pakistan",
        },
  
        {
          code: "+680-",
          name: "Palau",
        },
  
        {
          code: "+970-",
          name: "Palestine",
        },
  
        {
          code: "+507-",
          name: "Panama",
        },
  
        {
          code: "+675-",
          name: " Papua New Guinea",
        },
  
        {
          code: "+595-",
          name: "Paraguay",
        },
  
        {
          code: "+51-",
          name: " Peru",
        },
  
        {
          code: "+63-",
          name: "Philippines",
        },
  
        {
          code: "+64-",
          name: " Pitcairn",
        },
  
        {
          code: "+870-",
          name: " Pitcairn",
        },
  
        {
          code: "+48-",
          name: " Poland",
        },
  
        {
          code: "+351-",
          name: " Portugal",
        },
  
        {
          code: "+1939-",
          name: "Puerto Rico",
        },
  
        {
          code: "+974-",
          name: " Qatar",
        },
  
        {
          code: "+242-",
          name: " Republic of The Congo",
        },
  
        {
          code: "+262-",
          name: "Reunion",
        },
  
        {
          code: "+40-",
          name: "  Romania",
        },
  
        {
          code: "+7-",
          name: "Russian Federation",
        },
  
        {
          code: "+250-",
          name: "Rwanda",
        },
  
        {
          code: "+1869-",
          name: "Saint Kitts and Nevis",
        },
  
        {
          code: "+1758-",
          name: "Saint Lucia",
        },
  
        {
          code: "+590-",
          name: "Saint Martin",
        },
  
        {
          code: "+1784-",
          name: "Saint Vincent and The Grenadines",
        },
  
        {
          code: "+685-",
          name: "Samoa",
        },
  
        {
          code: "+378-",
          name: "San Marino",
        },
  
        {
          code: "+239-",
          name: " Sao Tome and Principe",
        },
  
        {
          code: "+966-",
          name: "Saudi Arabia",
        },
  
        {
          code: "+221-",
          name: "Senegal",
        },
  
        {
          code: "+381-",
          name: "Serbia",
        },
  
        {
          code: "+248-",
          name: "Seychelles",
        },
  
        {
          code: "+232-",
          name: "Sierra Leone",
        },
  
        {
          code: "+65-",
          name: "Singapore",
        },
  
        {
          code: "+1721-",
          name: " Sint Maarten",
        },
  
        {
          code: "+421-",
          name: "Slovakia",
        },
  
        {
          code: "+386-",
          name: " Slovenia",
        },
  
        {
          code: "+677-",
          name: "Solomon Islands",
        },
  
        {
          code: "+252-",
          name: "Somalia",
        },
  
        {
          code: "+27-",
          name: "South Africa",
        },
  
        {
          code: "+211-",
          name: " South Sudan",
        },
  
        {
          code: "+34-",
          name: "Spain",
        },
  
        {
          code: "+94-",
          name: "Sri Lanka",
        },
  
        {
          code: "+290-",
          name: " St. Helena",
        },
  
        {
          code: "+508-",
          name: " St. Pierre and Miquelon",
        },
  
        {
          code: "+249-",
          name: " Sudan",
        },
  
        {
          code: "+597-",
          name: "Suriname",
        },
  
        {
          code: "+47-",
          name: " Svalbard and Jan Mayen",
        },
  
        {
          code: "+268-",
          name: " Swaziland",
        },
  
        {
          code: "+46-",
          name: " Sweden",
        },
  
        {
          code: "+41-",
          name: " Switzerland",
        },
  
        {
          code: "+963-",
          name: " Syria",
        },
  
        {
          code: "+886-",
          name: "Taiwan",
        },
  
        {
          code: "+992-",
          name: "Tajikistan",
        },
  
        {
          code: "+255-",
          name: " Tanzania",
        },
  
        {
          code: "+66-",
          name: " Thailand",
        },
  
        {
          code: "+228-",
          name: " Togo",
        },
  
        {
          code: "+690-",
          name: " Tokelau",
        },
  
        {
          code: "+676-",
          name: " Tonga",
        },
  
        {
          code: "+1868-",
          name: " Trinidad and Tobago",
        },
  
        {
          code: "+216-",
          name: " Tunisia",
        },
  
        {
          code: "+90-",
          name: " Turkey",
        },
  
        {
          code: "+993-",
          name: " Turkmenistan",
        },
  
        {
          code: "+1649-",
          name: "Turks and Caicos Islands",
        },
  
        {
          code: "+688-",
          name: " Tuvalu",
        },
  
        {
          code: "+1340-",
          name: " U.S. Virgin Islands",
        },
  
        {
          code: "+256-",
          name: "  Uganda",
        },
  
        {
          code: "+380-",
          name: " Ukraine",
        },
  
        {
          code: "+971-",
          name: " United Arab Emirates",
        },
  
        {
          code: "+44-",
          name: "  United Kingdom",
        },
  
        {
          code: "+1-",
          name: "United States of America",
        },
  
        {
          code: "+598-",
          name: " Uruguay",
        },
  
        {
          code: "+581-",
          name: " Us Minor Outlying Islands",
        },
  
        {
          code: "+998-",
          name: "Uzbekistan",
        },
  
        {
          code: "+678-",
          name: "Vanuatu",
        },
  
        {
          code: "+379-",
          name: " Vatican City State (Holy See)",
        },
  
        {
          code: "+58-",
          name: " Venezuela",
        },
  
        {
          code: "+84-",
          name: "Vietnam",
        },
  
        {
          code: "+284-",
          name: "Virgin Islands (British)",
        },
  
        {
          code: "+340-",
          name: "Virgin Islands (U.S.)",
        },
  
        {
          code: "+681-",
          name: "Wallis and Futuna Islands",
        },
  
        {
          code: "+212-",
          name: "Western Sahara",
        },
  
        {
          code: "+967-",
          name: "Yemen",
        },
  
        {
          code: "+38-",
          name: "Yugoslavia",
        },
  
        {
          code: "+260-",
          name: "Zambia",
        },
  
        {
          code: "+263-",
          name: " Zimbabwe",
        },
      ],
      //500 error message
      leadMessage: "user already exist",
      leadstatus: false,
    }),
  
    created() {
      this.widget_id = this.widget_prop_id;
      this.utmSource = this.$route.query.utm_source || "Organic Search";
      this.utmSourceCampaign = this.$route.query.utm_campaign || "website";
      this.utmSourceMedium = this.$route.query.utm_medium || "";
      this.utmAdgroupid = this.$route.query.utm_adgroupid || "";
      this.utmCampaignid = this.$route.query.utm_campaignid || "";
      this.utmCreativeid = this.$route.query.utm_creativeid || "";
      this.utmDevice = this.$route.query.utm_device || "";
      this.utmKeyword = this.$route.query.utm_keyword || "";
      this.utmMatchtype = this.$route.query.utm_matchtype || "";
      this.utmNetwork = this.$route.query.utm_network || "";
      this.utmPlacement = this.$route.query.utm_placement || "";
      this.Gclid = this.$route.query.gclid || "";
    },
  
    methods: {
      validateInput(event) {
        const regex = /^[A-Za-z\s]*$/;
        if (!regex.test(event.target.value)) {
          event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
        }
        this.name = event.target.value;
      },
  
      // sanitizeInput() {
      //   // Remove no-nnumeric characters
      //   this.enteredOtp = this.enteredOtp.replace(/[^0-9]/g, "");
      // },
  
      // onPhoneNumberChange() {
      //   // Reset the error message when the phone number changes
      //   this.message = "";
  
      //   // Reset otpSent when the phone number changes
      //   this.otpSent = false;
  
      //   // Remove no-nnumeric characters from the phone number
      //   this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
  
      //   // Limit the phone number to a maximum length of 10 digits
      //   if (this.phoneNumber.length > 10) {
      //     this.phoneNumber = this.phoneNumber.slice(0, 10);
      //   }
      // },
  
      // handleKeyPress(event) {
      //   // Allow only numeric input and backspace
      //   if (
      //     !(event.charCode >= 48 && event.charCode <= 57) &&
      //     event.charCode !== 8
      //   ) {
      //     event.preventDefault();
      //   }
      // },
      // clearFormStatus() {
      //   this.enteredOtp = "";
      //   this.verified = false;
      //   this.submissionStatus = "";
      //   this.otpBasedError = "";
      // },
      // startTimer() {
      //   if (this.timeLeft === null) {
      //     this.timeLeft = 120; // 2 minutes in seconds
      //     this.timerstatus = true;
      //     this.countdown();
      //   }
      // },
      // countdown() {
      //   if (this.timeLeft > 0) {
      //     setTimeout(() => {
      //       this.timeLeft--;
      //       this.countdown();
      //     }, 1000);
      //     console.log("this.timeLeft : ", this.timeLeft);
      //   } else {
      //     this.timeLeft = null; // Reset timer
      //   }
      // },
  
      async enquireSubmit(el) {
        const dataJson = [
          {
            Attribute: "FirstName",
            Value: el.target.elements.name.value,
          },
          {
            Attribute: "EmailAddress",
            Value: "",
          },
          {
            Attribute: "Phone",
            Value: this.selectedCountryCode + this.phoneNumber,
          },
          {
            Attribute: "mx_State",
            Value: "",
          },
          {
            Attribute: "mx_City",
            Value: "",
          },
          {
            Attribute: "mx_Program",
            Value: "",
          },
          {
            Attribute: "mx_Elective",
            Value: "",
          },
          {
            Attribute: "mx_utm_Source",
            Value: this.utmSource,
          },
          {
            Attribute: "SourceCampaign",
            Value: this.utmSourceCampaign,
          },
          {
            Attribute: "SourceMedium",
            Value: this.utmSourceMedium,
          },
          {
            Attribute: "mx_utm_Adgroupid",
            Value: this.utmAdgroupid,
          },
          {
            Attribute: "mx_utm_Campaignid",
            Value: this.utmCampaignid,
          },
          {
            Attribute: "mx_utm_Creativeid",
            Value: this.utmCreativeid,
          },
          {
            Attribute: "mx_utm_Device",
            Value: this.utmDevice,
          },
          {
            Attribute: "mx_utm_Keyword",
            Value: this.utmKeyword,
          },
          {
            Attribute: "mx_utm_Matchtype",
            Value: this.utmMatchtype,
          },
          {
            Attribute: "mx_utm_Network",
            Value: this.utmNetwork,
          },
          {
            Attribute: "mx_utm_Placement",
            Value: this.utmPlacement,
          },
          {
            Attribute: "mx_Gclid",
            Value: this.Gclid,
          },
        ];
        // Call the API to capture lead
        await axios
          .post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r69fc09238684797dee360a6573e1948c&secretKey=d94e9582525478b9b4a15c6328c27f697bf376e8",
            dataJson
          )
          .then((resp) => {
            // Handle lead capture response
            console.log("Lead capture successful:", resp.data);
            // this.errorStatus = false;
            // el.target.reset();
            // this.showForm = false;
            if (resp.data.Status == "Success") {
              if (resp.data.Message.IsCreated == true) {
                this.showForm = false;
                this.errorStatus = false;
                el.target.reset();
                this.$router.push({
                  path: `/lp/online-degree-programs/thankyou`,
                });
              } else if (resp.data.Message.IsCreated == false) {
                this.leadstatus = true;
                this.leadMessage = "user already exist";
              } else {
                this.leadstatus = true;
                this.leadMessage = "Mobile number already exist";
              }
            }
          })
          .catch((error) => {
            this.leadMessage = error.response.data.ExceptionMessage;
            this.leadstatus = true;
            setTimeout(() => {
              this.leadstatus = false;
            }, 2000);
          });
  
        // try {
        //   // Call the API to verify OTP
        //   let json_data = {
        //     phone: this.$refs.phone.value,
        //     otp: this.enteredOtp,
        //   };
        //   axios
        //     .post(`${process.env.VUE_APP_API}/mob_otp_check/`, json_data)
        //     .then(async (resp) => {
        //       console.log(resp, "respresprespresp");
        //       if (resp.data.status == 200) {
        //         this.otpChecking = true;
  
        //       } else {
        //         this.otpChecking = false;
        //         this.errorMessage = "Incorrect OTP. Please try again.";
        //         this.errorStatus = true;
        //       }
        //     });
  
        //   // if (this.otpChecking) {
  
        //   // } else {
        //   //   // Handle OTP verification failure, for example, show an error message
  
        //   // }
        // } catch (error) {
        //   // Handle error, for example, show a generic error message
        //   console.error("Error:", error);
        //   this.errorMessage = "An error occurred. Please try again later.";
        // }
      },
  
      // updateElectiveOptions() {
      //   // Define elective options based on the selected program
      //   if (this.selectedProgram === "MBA") {
      //     this.electiveOptions = [
      //       { value: "Finance", label: "Finance" },
      //       { value: "Marketing", label: "Marketing" },
      //       {
      //         value: "Human Resource Management",
      //         label: "Human Resource Management",
      //       },
      //       {
      //         value: "International Business",
      //         label: "International Business",
      //       },
      //       {
      //         value: "Data Science and Analytics",
      //         label: "Data Science and Analytics",
      //       },
  
      //       {
      //         value: "Operations Management",
      //         label: "Operations Management",
      //       },
      //       // { value: "International Business", label: "International Business" },
  
      //       {
      //         value: "Project Management",
      //         label: "Project Management",
      //       },
      //       {
      //         value: " Logistics and Supply Chain Management",
      //         label: " Logistics and Supply Chain Management",
      //       },
      //       {
      //         value: "Digital Marketing and E-commerce",
      //         label: "Digital Marketing and E-commerce",
      //       },
      //       {
      //         value: "Healthcare and Hospital Administration",
      //         label: "Healthcare and Hospital Administration",
      //       },
      //       {
      //         value: "International Finance (Integrated by ACCA, UK)",
      //         label: "International Finance (Integrated by ACCA, UK)",
      //       },
      //     ];
      //   } else if (this.selectedProgram === "MCA") {
      //     this.electiveOptions = [
      //       {
      //         value: "Computer Science and IT",
      //         label: "Computer Science and IT",
      //       },
      //       { value: "Data Science", label: "Data Science" },
      //       {
      //         value: "Artificial Intelligence",
      //         label: "Artificial Intelligence",
      //       },
      //       { value: "Cyber security", label: "Cyber security" },
      //       { value: "Cloud Computing", label: "Cloud Computing" },
      //     ];
      //   } else if (this.selectedProgram === "M.Com") {
      //     this.electiveOptions = [
      //       {
      //         value: "Accounting and Finance",
      //         label: "Accounting and Finance",
      //       },
      //       {
      //         value: "International Finance (Integrated by ACCA, UK)",
      //         label: "International Finance (Integrated by ACCA, UK)",
      //       },
      //     ];
      //   } else if (this.selectedProgram === "BCA") {
      //     this.electiveOptions = [
      //       {
      //         value: "BCA Program",
      //         label: "BCA Program",
      //       },
      //     ];
      //   } else if (this.selectedProgram === "BBA") {
      //     this.electiveOptions = [
      //       { value: "BBA Program", label: "BBA Program" },
      //       {
      //         value: "International Finance (Integrated by ACCA, UK)",
      //         label: "International Finance (Integrated by ACCA, UK)",
      //       },
      //     ];
      //   } else if (this.selectedProgram === "BA(Hons.)") {
      //     this.electiveOptions = [
      //       { value: "Political Science", label: "Political Science" },
      //     ];
      //   }
      // },
    },
  };
  </script>
  <style scoped></style>
  