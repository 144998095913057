<template>
  <div class="">
    <headerView />
    <!-- banner -->
    <div class="bg-gradient-to-b from-white to-[#EFEFEF] px-5 lg:px-8">
      <div class="max-w-7xl mx-auto mt-[3rem] lg:mt-[8rem]">
        <div class="px-5">
          <p class="text-[#0CB1EF] md:text-black text-[14px] hidden lg:block">
            <a href="/" class="text-[#EC027C]">Home</a>
            <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
            <a class="text-[#4D4D4D]">Contact us</a>
          </p>
        </div>
        <div class="max-w-6xl mx-auto">
          <div class="flex flex-col justify-center lg:h-[260px]">
            <div class="flex flex-wrap ">
              <div class="mt-8 flex flex-col gap-3 w-full lg:w-[50%] ">
                <h2
                  class="text-[30px] lg:text-[45px] xl:text-[60px] font-medium lg:w-[60rem] lg:leading-[4rem] text-[#18479E]"
                >
                  Get in Touch With <br />
                  <span class="text-[#EC027C]"
                    >Our Team For 
                    Prompt Assistance</span
                  >
                </h2>
                <p class="text-[#000000] text-[24px] font-medium">
                  Talk to our experts
                </p>
              </div>
              <div class="py-6 lg:py-0 w-full lg:w-[50%] hidden">
                <div class="lg:px-10 xl:px-14">
                  <form action="">
                    <div class="flex flex-col gap-4">
                      <input
                        type="text"
                        name="name"
                        class="p-2 rounded-full w-full border border-[#4D4D4D] outline-[#EC027C] placeholder-[#4D4D4D]"
                        placeholder="Enter Name *"
                      />
                      <input
                        type="email"
                        placeholder="Enter Email Address *"
                        class="p-2 rounded-full w-full border border-[#4D4D4D] outline-[#EC027C] placeholder-[#4D4D4D]"
                      />
                      <div class="flex gap-4">
                        <select
                          name="country_id"
                          class="text-sm w-[83px] mx-auto bg-white p-2 border border-[#4D4D4D] rounded-full text-[#EC027C] placeholder-[#4D4D4D]"
                        >
                          <option value="" class="">+91</option>
                        </select>
                        <input
                          type="tel"
                          placeholder="Enter Mobile Number *"
                          class="p-2 rounded-full w-full border border-[#4D4D4D] pr-12 outline-[#EC027C] placeholder-[#4D4D4D]"
                        />
                      </div>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        class="border border-[#4D4D4D] rounded-2xl p-3 placeholder-[#4D4D4D]"
                        placeholder="your message"
                      ></textarea>

                      <div
                        class="flex gap-4 text-xs items-start text-left text-black"
                      >
                        <input
                          type="checkbox"
                          checked
                          class="accent-[#EC027C]"
                        />
                        <span class="text-justify"
                          >I agree with the terms & conditions</span
                        >
                      </div>
                      <div class="flex justify-center">
                        <button
                          type="submit"
                          class="lg:w-max w-full text-white text-sm font-semibold bg-[#EC027C] hover:bg-[#ca9216] rounded-full px-9 py-3 lg:py-2 text-center m-auto"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- write us -->
    <div class="px-5 lg:px-8">
      <div class="max-w-7xl mx-auto py-5 lg:py-20">
        <div class="max-w-5xl mx-auto">
          <div class="flex flex-col gap-4">
            <div class="flex gap-4 items-end">
              <h2
                class="mt-4 text-[#18479E] leading-tight text-[30px] lg:text-[60px]"
              >
                Write <span class="text-[#ec027c]">us</span>
              </h2>

              <div class="w-[300px] hidden lg:block">
                <img
                  src="@/assets/contact/element.svg"
                  alt="element icon"
                  class="w-[263px] h-[164px]"
                />
              </div>
            </div>

            <div
              class="flex flex-col gap-3 justify-center lg:flex-row lg:justify-between bg-white lg:p-2 rounded-lg"
            >
              <a
                href="https://api.whatsapp.com/send/?phone=916361202000&text&app_absent=0"
                target="_blank"
              >
                <div class="flex items-center gap-3">
                  <div class="">
                    <img
                      src="@/assets/contact/Group 734.svg"
                      alt=""
                      srcset=""
                      class="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] p-2"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1
                      class="text-left text-[16px] lg:text-[24px] font-normal text-[#ec027c]"
                    >
                      WhatsApp Us
                    </h1>
                    <p
                      class="font-bold text-[#18479E] text-[18px] lg:text-[30px]"
                    >
                      +91-6361202000
                    </p>
                  </div>
                </div>
              </a>

              <a href="tel:06361202000" target="_blank">
                <div class="flex items-center gap-3">
                  <div class="">
                    <img
                      src="../assets/contact/Icon material-call.svg"
                      alt=""
                      srcset=""
                      class="h-[36px] lg:w-[57px] lg:h-[57px] p-2"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1
                      class="text-left text-[16px] lg:text-[24px] font-normal text-[#ec027c]"
                    >
                      Call Us
                    </h1>
                    <p
                      class="font-bold text-[#18479E] text-[18px] lg:text-[30px]"
                    >
                      +91-6361202000
                    </p>
                  </div>
                </div>
              </a>
              <a href="mailto: enrol@onlinejain.com" target="_blank">
                <div class="flex items-center gap-3">
                  <div class="">
                    <img
                      src="../assets/contact/Icon feather-mail.svg"
                      alt=""
                      srcset=""
                      class="h-[36px] lg:w-[57px] lg:h-[57px] p-2"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1
                      class="text-left text-[16px] lg:text-[24px] font-normal text-[#ec027c]"
                    >
                      Email Us
                    </h1>
                    <p
                      class="font-bold text-[#18479E] text-[18px] lg:text-[30px]"
                    >
                      info@shardaonline.ac.in
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- university -->

    <div class="px-5 lg:px-0 lg:w-full py-5 lg:py-0 lg:pt-15">
      <div class="flex flex-col gap-3">
        <h2
          class="mt-4 text-[#18479E] font-normal leading-tight text-[30px] text-left lg:text-center lg:text-[60px]"
        >
          University <span class="text-[#ec027c]">Address</span>
        </h2>
        <div>
          <iframe
            data-v-aa5ee9c6=""
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.215143797316!2d77.48033507549496!3d28.473066775752244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cea0f7b91511b%3A0x486f13bd49e5e7ae!2sSHARDA%20UNIVERSITY!5e0!3m2!1sen!2sin!4v1714394894183!5m2!1sen!2sin"
            style="border: 0px none"
            class="h-[300px] lg:h-[600px]"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            width="100%"
            height=""
          ></iframe>
        </div>
      </div>
    </div>
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";

export default {
  name: "ContactView",
  components: {
    headerView,
    footerView,
    stickyView,
  },
};
</script>

<style></style>
