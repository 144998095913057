<template>
  <div class="bg-gradient-to-b from-white to-[#EFEFEF] pt-[95px] px-5 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <div class="py-5 lg:py-12">
        <div class="flex flex-wrap-reverse lg:flex-nowrap justify-around">
          <div class="flex flex-col gap-5 justify-between">
            <p class="text-[#0CB1EF] md:text-black text-[14px] hidden lg:block">
              <a href="/" class="text-[#EC027C]">Home</a>
              <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
              <a
                v-if="elective.program && elective.program.category"
                href="/"
                class="text-[#EC027C]"
              >
                {{ elective.program.category.name }}
              </a>
              <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
              <a
                v-if="elective.program"
                :href="'/' + elective.program.slug"
                class="text-[#EC027C]"
              >
                {{ elective.program.name }}
              </a>
              <span class="text-[#EC027C]">&nbsp;/&nbsp;</span>
              <span class="text-[#4D4D4D]"> {{ elective.name }}</span>
            </p>
            <div class="flex flex-col">
              <h1
                class="text-[#EC027C] text-[18px] lg:text-[20px] font-semibold"
              >
                {{ elective.sub_name }}
              </h1>
              <h1 class="text-[#18479E] text-[30px] lg:text-[40px] font-medium">
                {{ elective.name }}
              </h1>
            </div>
            <div class="py-2">
              <div class="flex items-center gap-x-4">
                <div class="flex items-center gap-x-3">
                  <div>
                    <img src="@/assets/programm/NAAC_A+.webp" alt="NAAC_A+" />
                  </div>
                  <div class="text-sm lg:text-lg font-bold">
                    NAAC A+ accredited
                  </div>
                </div>
                <div class="flex items-center gap-x-3">
                  <div>
                    <img
                      src="@/assets/programm/UGC_India_Logo.webp"
                      alt="ugc_india"
                    />
                  </div>
                  <div class="text-sm lg:text-lg font-bold">UGC-entitled</div>
                </div>
              </div>
            </div>
            <div class="flex gap-4">
              <div class="flex items-center gap-2">
                <div>
                  <img
                    src="@/assets/programm/lastDate.png"
                    alt="lastDate"
                    class="w-[18px] h-[18px] lg:w-[18px] lg:h-[18px]"
                  />
                </div>
                <div class="text-[11px] font-bold text-black lg:hidden">
                  Limited <br />
                  seats available!
                </div>
                <div class="text-[16px] font-bold text-black hidden lg:block">
                  Limited seats available!
                </div>
              </div>
              <div class="flex items-center gap-2">
                <div>
                  <img
                    src="@/assets/programm/limitedSeat.png"
                    alt="lastDate"
                    class="w-[18px] h-[18px] lg:w-[18px] lg:h-[18px]"
                  />
                </div>
                <div class="text-[11px] font-bold text-black lg:hidden">
                  Admission Open
                </div>
                <div class="text-[16px] font-bold text-black hidden lg:block">
                  Admission Open
                </div>
              </div>
            </div>
            <div class="flex gap-5">
              <a
                href="https://apply.shardaonline.ac.in/"
                target="_blank"
                class="text-[#EC027C] text-[14px] lg:text-[16px] flex justify-center items-center font-bold border bg-[#FFFFFF] rounded-full border-[#EC027C] w-[150px] lg:w-[160px] h-[50px]"
              >
                Apply Now
              </a>
              <a :href="elective.brochures" target="_blank">
                <button
                  class="text-[#EC027C] text-[14px] lg:text-[16px] font-bold border bg-[#FFFFFF] rounded-full border-[#EC027C] w-[150px] lg:w-[200px] h-[50px]"
                >
                  Download Brochure
                </button>
              </a>
            </div>
          </div>
          <div class="w-[380px] h-[360px]">
            <img
              :src="elective.banner"
              alt="bannerDesktop"
              v-if="elective.banner"
            />
            <img
              src="@/assets/elective/banner/petal@2x.png"
              alt="bannerdesk"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerView",
  props: ["elective"],
};
</script>

<style></style>
