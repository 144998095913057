<template>
    <section class="w-full my-10 lg:my-36 px-5 lg:px-0">
        <div class="max-w-7xl mx-auto">
            <div class="flex justify-between xl:justify-around textAnimation" ref="heading1" :class="{ visible: isVisible }">
                <h2 class="mt-4 text-[#18479E] px-12 leading-tight text-[60px] hidden lg:block !md:text-center">
                    Explore the Best
                    <span class="text-[#ec027c]">Online <br /> Degree Programs </span> in India
                </h2>

                <h2 class="mt-4 text-[#18479E] text-[30px] leading-tight lg:hidden">
                    Explore the Best <br />
                    <span class="text-[#ec027c]">Online Degree <br />
                        Programs</span>
                    in India
                </h2>
                <div class="w-[300px] hidden lg:block">
                    <img src="@/assets/home/degree/element.png" alt="element icon" />
                </div>
            </div>

            <div class="flex gap-5 mt-5 lg:px-12 xl:ml-14">
                <div class="text-[15px] lg:text-[28px] border-b pb-1 cursor-pointer whitespace-nowrap" :class="[
                    selectTab == 'masters'
                        ? ' text-[#18479E] border-[#18479E] font-bold'
                        : 'text-[#ec027c] border-[#ec027c] font-medium ',
                ]" @click="selectTab = 'masters'">
                    Master’s Degree
                </div>
                <div class="border-r-2 border-gray-400 mt-2"></div>
                <div class="text-[15px] lg:text-[28px] border-b pb-1 cursor-pointer whitespace-nowrap" :class="[
                    selectTab == 'bachelors'
                        ? ' text-[#18479E] border-[#18479E] font-bold'
                        : 'text-[#ec027c] border-[#ec027c]  font-medium',
                ]" @click="selectTab = 'bachelors'">
                    Bachelor’s Degree
                </div>
            </div>

            <!-- Accordion content for Masters and Bachelors -->
            <!-- desktop -->
            <div name="accordion" class="hidden lg:block">
                <div v-if="selectTab === 'masters'">
                    <div v-for="(item, index) in mastersData" :key="index" class="accordion-item">
                        <div class="accordion-title text-[15px] lg:text-[28px] pt-5 font-bold cursor-pointer whitespace-nowrap flex justify-start items-center"
                            @mouseover="toggleAccordion(index, 'masters')" :class="[
                                activeAccordions.masters === index
                                    ? 'text-[#ec027c]'
                                    : 'text-[#18479E]',
                            ]">
                            <div v-if="activeAccordions.masters !== index"
                                class="w-full flex flex-col px-12 border-b h-[160px]">
                                <div class="flex justify-between items-center h-full">
                                    <span>{{ item.title }}</span>
                                    <img v-if="activeAccordions.masters !== index" src="@/assets/home/degree/Group-1465.svg"
                                        alt="icon" class="mr-[12%]" />
                                </div>
                            </div>
                        </div>

                        <div v-if="activeAccordions.masters === index" class="accordion-content mt-2 relative h-[16rem]">
                            <img :src="item.bgImage" alt="Background Image"
                                class="absolute top-0 left-0 w-full h-full z-0 object-cover" />
                            <div class="absolute top-0 px-8 py-2 w-full z-10">
                                <div class="flex items-center">
                                    <div class="flex flex-col justify-start items-start gap-2">
                                        <p class="text-[#0CB1EF] text-[30px] font-bold">
                                            {{ item.title }}
                                        </p>
                                        <p class="text-white text-[18px] md:w-[75%] md: h-[50px] md:mb-4">
                                            {{ item.description }}
                                        </p>
                                        <div class="flex gap-20 pt-3">
                                            <div class="flex items-center gap-5 w-[130px]">
                                                <img src="@/assets/home/degree/Icon-awesome-graduation-cap.png" alt=""
                                                    class="w-[29px] h-[18px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.course }}
                                                </p>
                                            </div>
                                            <div class="flex items-center gap-5 w-[130px]">
                                                <img src="@/assets/home/degree/Icon-feather-calendar.png" alt=""
                                                    class="w-[18px] h-[20px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.years }}
                                                </p>
                                            </div>
                                            <div class="flex items-center gap-5 w-[150px]">
                                                <img src="@/assets/home/degree/fees.png" alt="" class="w-[29px] h-[29px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.fees }}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="https://apply.shardaonline.ac.in/">
                                                <button
                                                    class="absolute bg-[#EC027C] w-[160px] h-[50px] text-[16px] rounded-3xl text-white font-bold bottom-[-30%]">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="flex flex-row items-center">
                                        <a :href="`/${item.slug}/${item.short_slug}`">
                                            <!-- <p class="text-white text-[18px] font-normal absolute right-[17rem] bottom-10">
                                                Read More
                                            </p> -->

                                            <img src="@/assets/home/degree/more-icon.png" alt=""
                                                class="absolute right-[13rem] bottom-5" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="selectTab === 'bachelors'" class="mt-5">
                    <div v-for="(item, index) in bachelorsData" :key="index" class="accordion-item">
                        <div class="accordion-title text-[15px] lg:text-[28px] pb-1 font-bold cursor-pointer whitespace-nowrap pt-4"
                            @mouseover="toggleAccordion(index, 'bachelors')" :class="[
                                activeAccordions.bachelors === index
                                    ? 'text-[#ec027c]'
                                    : 'text-[#18479E]',
                            ]">
                            <div class="w-full flex flex-col px-12 gap-10">
                                <div class="flex justify-between items-center">
                                    <span v-if="activeAccordions.bachelors !== index">{{
                                        item.title
                                    }}</span>
                                    <img v-if="activeAccordions.bachelors !== index"
                                        src="@/assets/home/degree/Group-1465.svg" alt="icon" class="mr-[12%]" />
                                </div>
                                <div class="border-b shadow-lg" v-if="activeAccordions.bachelors !== index"></div>
                            </div>
                        </div>
                        <div v-if="activeAccordions.bachelors === index" class="accordion-content mt-2 relativen h-[16rem]">
                            <img :src="item.bgImage" alt="Background Image"
                                class="absolute top-0 left-0 w-full h-full z-0 object-cover" />
                            <div class="absolute top-0 px-8 py-2 w-full z-10">
                                <div class="flex items-center">
                                    <div class="flex flex-col justify-start items-start gap-2">
                                        <p class="text-[#0CB1EF] md:text-[30px] text-[10px] font-bold">
                                            {{ item.title }}
                                        </p>
                                        <p class="text-white text-[18px] md:w-[75%] md:h-[50px] md:mb-4">
                                            {{ item.description }}
                                        </p>
                                        <div class="flex gap-20 pt-3">
                                            <div class="flex items-center gap-5 w-[130px]">
                                                <img src="@/assets/home/degree/Icon-awesome-graduation-cap.png" alt=""
                                                    class="w-[29px] h-[18px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.course }}
                                                </p>
                                            </div>
                                            <div class="flex items-center gap-5 w-[130px]">
                                                <img src="@/assets/home/degree/Icon-feather-calendar.png" alt=""
                                                    class="w-[18px] h-[20px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.years }}
                                                </p>
                                            </div>
                                            <div class="flex items-center gap-5 w-[150px]">
                                                <img src="@/assets/home/degree/fees.png" alt="" class="w-[29px] h-[29px]" />
                                                <p class="text-white font-bold text-[20px]">
                                                    {{ item.fees }}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="https://apply.shardaonline.ac.in/">
                                                <button
                                                    class="absolute bg-[#EC027C] w-[160px] h-[50px] text-[16px] rounded-3xl text-white font-bold top-[195px]">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="flex flex-row items-center">
                                        <a :href="`/${item.slug}/${item.short_slug}`">
                                        <!-- <p class="text-white text-[18px] font-normal absolute right-[17rem] bottom-10">
                                            Read More
                                        </p> -->
                                        <img src="@/assets/home/degree/more-icon.png" alt=""
                                            class="absolute right-[13rem] bottom-5" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- desktop -->
            <!-- mobile -->
            <div class="lg:hidden">
                <div class="w-full mx-auto">
                    <!-- Masters Data -->
                    <div v-if="selectTab === 'masters'" class="mt-5">
                        <div v-for="(item, index) in mastersData" :key="index" class="w-full mb-5"
                            :class="{ 'mt-5': index !== 0 }">
                            <div class="relative h-[350px] w-full ssm:w-full" :class="[
                                'rounded-lg',
                                activeAccordions.masters === index
                                    ? 'bg-white shadow-lg'
                                    : 'border-2 border-gray-200',
                            ]" @mouseover="toggleAccordion(index, 'masters')">
                                <img v-if="activeAccordions.masters === index" :src="item.bgImgMob" alt="background"
                                    class="absolute w-full h-full object-cover rounded-xl" />
                                <div class="absolute ssm:p-1 p-3"
                                    :class="{ 'text-black': activeAccordions.masters !== index }">
                                    <p :class="[
                                        activeAccordions.masters === index
                                            ? 'text-[#0CB1EF] font-bold md:text-[28px] text-[18px]'
                                            : 'text-[#18479E] font-medium ',
                                        ' text-[24px] text-left',
                                    ]">
                                        {{ item.title }}
                                    </p>
                                    <p :class="[
                                        activeAccordions.masters === index
                                            ? 'text-white font-medium'
                                            : 'text-[#4D4D4D] font-normal',
                                        'pt-3 text-[14px] text-left ssm:pr-3 md:w-[75%]',
                                    ]">
                                        {{ item.description }}
                                    </p>
                                    <div class="flex w-full pt-5">
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.masters === index
                                                    ? require('@/assets/home/degree/Icon-awesome-graduation-cap.png')
                                                    : require('@/assets/home/degree/Icon-awesome-graduation-cap1.png')
                                                " alt="" class="w-[20px] h-[13px]" />
                                            <p :class="[
                                                activeAccordions.masters === index
                                                    ? 'text-white font-bold '
                                                    : 'text-[#000000]',
                                                'text-[14px] ssm:text-[13px] ',
                                            ]">
                                                {{ item.course }}
                                            </p>
                                        </div>
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.masters === index
                                                    ? require('@/assets/home/degree/Icon-feather-calendar.png')
                                                    : require('@/assets/home/degree/Icon-feather-calendar1.png')
                                                " alt="" class="w-[14px] h-[15px]" />
                                            <p :class="[
                                                activeAccordions.masters === index
                                                    ? 'text-white  font-bold '
                                                    : 'text-black',
                                                'text-[14px] ssm:text-[13px]',
                                            ]">
                                                {{ item.years }}
                                            </p>
                                        </div>
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.masters === index
                                                    ? require('@/assets/home/degree/fees.png')
                                                    : require('@/assets/home/degree/Group-947.png')
                                                " alt="" class="w-[20px] h-[20px]" />
                                            <p :class="[
                                                activeAccordions.masters === index
                                                    ? 'text-white font-bold'
                                                    : 'text-black',
                                                'text-[14px] ssm:text-[13px] ',
                                            ]">
                                                {{ item.fees }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="w-full flex pt-8 items-center gap-20 ssm:gap-16">
                                        <a href="https://apply.shardaonline.ac.in/" target="_blank">
                                            <button class="text-[16px] font-medium w-[150px] h-[50px] rounded-3xl" :class="[
                                                activeAccordions.masters === index
                                                    ? 'bg-[#EC027C] text-white'
                                                    : 'bg-[#EC027C] text-white',
                                            ]">
                                                Apply Now
                                            </button>
                                        </a>
                                        <a :href="`/${item.slug}/${item.short_slug}`">
                                            <img :src="activeAccordions.masters === index
                                                    ? require('@/assets/home/degree/more-icon.png')
                                                    : require('@/assets/home/degree/Group-1465.svg')
                                                " alt="icon" class="w-[46px] h-[46px]" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Bachelors Data (similar to Masters, apply the same changes) -->
                    <div v-if="selectTab === 'bachelors'" class="mt-5">
                        <div v-for="(item, index) in bachelorsData" :key="index" class="w-full mb-5"
                            :class="{ 'mt-5': index !== 0 }">
                            <div class="relative h-[350px] w-full ssm:w-full" :class="[
                                'rounded-lg',
                                activeAccordions.bachelors === index
                                    ? 'bg-white shadow-lg'
                                    : 'border-2 border-gray-200',
                            ]" @mouseover="toggleAccordion(index, 'bachelors')">
                                <img v-if="activeAccordions.bachelors === index" :src="item.bgImgMob" alt="background"
                                    class="absolute w-full h-full object-cover rounded-xl" />
                                <div class="absolute ssm:p-1 p-3" :class="{
                                    'text-black': activeAccordions.bachelors !== index,
                                }">
                                    <p :class="[
                                        activeAccordions.bachelors === index
                                            ? 'text-[#0CB1EF]  font-bold md:text-[28px] text-[18px]'
                                            : 'text-[#18479E] font-medium ',
                                        'text-[24px]  text-left',
                                    ]">
                                        {{ item.title }}
                                    </p>
                                    <p :class="[
                                        activeAccordions.bachelors === index
                                            ? 'text-white font-medium'
                                            : 'text-black font-normal',
                                        'pt-3 text-[14px] text-left ssm:pr-3 md:w-[75%]',
                                    ]">
                                        {{ item.description }}
                                    </p>
                                    <div class="flex w-full pt-5">
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.bachelors === index
                                                    ? require('@/assets/home/degree/Icon-awesome-graduation-cap.png')
                                                    : require('@/assets/home/degree/Icon-awesome-graduation-cap1.png')
                                                " alt="" class="w-[20px] h-[13px]" />
                                            <p :class="[
                                                activeAccordions.bachelors === index
                                                    ? 'text-white font-bold'
                                                    : 'text-black',
                                                'text-[14px] ssm:text-[13px] ',
                                            ]">
                                                {{ item.course }}
                                            </p>
                                        </div>
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.bachelors === index
                                                    ? require('@/assets/home/degree/Icon-feather-calendar.png')
                                                    : require('@/assets/home/degree/Icon-feather-calendar1.png')
                                                " alt="" class="w-[14px] h-[15px]" />
                                            <p :class="[
                                                activeAccordions.bachelors === index
                                                    ? 'text-white '
                                                    : 'text-black',
                                                'text-[14px] ssm:text-[13px] font-bold',
                                            ]">
                                                {{ item.years }}
                                            </p>
                                        </div>
                                        <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                                            <img :src="activeAccordions.bachelors === index
                                                    ? require('@/assets/home/degree/fees.png')
                                                    : require('@/assets/home/degree/Group-947.png')
                                                " alt="" class="w-[20px] h-[20px]" />
                                            <p :class="[
                                                activeAccordions.bachelors === index
                                                    ? 'text-white font-bold'
                                                    : 'text-black',
                                                'text-[14px] ssm:text-[13px] ',
                                            ]">
                                                {{ item.fees }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="w-full flex pt-8 items-center gap-20 ssm:gap-16">
                                        <a href="https://apply.shardaonline.ac.in/">
                                            <button class="text-[16px] font-medium w-[150px] h-[50px] rounded-3xl" :class="[
                                                activeAccordions.bachelors === index
                                                    ? 'bg-[#EC027C] text-white'
                                                    : 'bg-[#EC027C] text-white',
                                            ]">
                                                Apply Now
                                            </button>
                                        </a>
                                        <a :href="`/${item.slug}/${item.short_slug}`">
                                            <img :src="activeAccordions.bachelors === index
                                                    ? require('@/assets/home/degree/more-icon.png')
                                                    : require('@/assets/home/degree/Group-1465.svg')
                                                " alt="icon" class="w-[46px] h-[46px]" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <!-- <p class="text-[#EC027C] text-[18px] font-medium text-center lg:mt-20">
        View All Programs
      </p> -->
        </div>
    </section>
</template>

<script>
export default {
    name: "ExploreDegree",
    data() {
        return {
            selectTab: "masters",
            mastersData: [
                {
                    title: "Finance",
                    description:
                        "This two-year Online MBA in Finance equips learners with the skills needed for managerial and strategic financial roles, focusing on using economic data for informed decision-making.  ",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "finance",
                    bgImage: require('@/assets/home/degree/Finance.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Finance.webp')
                },
                {
                    title: "Marketing",
                    description:
                        "This two-year Online MBA in Marketing prepares individuals for advanced professional roles by enhancing their product lifecycle management, market research, and brand management skills.  ",
                    years: "2 years",
                    fees: "₹ 1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "marketing",
                    bgImage: require('@/assets/home/degree/Marketing.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Marketing.webp')
                },
                {
                    title: "Human Resource Management",
                    description:
                        "This two-year Online MBA in Human Resource Management equips individuals with essential HR skills, including training, recruitment, and performance management, tailored to current industry needs.  ",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "human-resource-management",
                    bgImage: require('@/assets/home/degree/Human-Resource-Management.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Human-Resource-Management.webp')
                },
                {
                    title: "Data Science and Analytics",
                    description:
                        "This two-year Online MBA in Data Science and Analytics equips individuals with essential business administration, data analysis, and technology skills tailored to current industry demands. ",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "data-science-and-analytics",
                    bgImage: require('@/assets/home/degree/Data-Science-and-Analytics.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Data-Science-and-Analytics.webp')
                },
                {
                    title: "Operations Management",
                    description:
                        "This two-year Online MBA in Operations Management equips learners with advanced knowledge and skills in applying modern technologies like IoT, AI, and Blockchain to optimize operations. ",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "operation-management",
                    bgImage: require('@/assets/home/degree/Operations-Management.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Operations-Management.webp')
                },
                {
                    title: "International Business",
                    description:
                        "This two-year Online MBA in International Business equips learners with in-depth knowledge of international markets, finance, and cross-cultural business practices, enabling them to excel in global trade.",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "international-business",
                    bgImage: require('@/assets/home/degree/International-Business.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/International-Buisness.webp')
                },
                {
                    title: "Project Management",
                    description:
                        "This two-year Online MBA in Project Management equips learners with essential skills in business administration, leadership, and project planning tailored to meet current industry demands. ",
                    years: "2 years",
                    fees: "₹1,00,000",
                    course: "MBA",
                    slug: "online-mba",
                    short_slug: "project-management",
                    bgImage: require('@/assets/home/degree/Project-Management.webp'),
                    bgImgMob: require('@/assets/home/degree/mobile/Project-Management.webp')
                },
            ],
            bachelorsData: [
                {
                    title: "International Finance (Accredited by ACCA, UK)",
                    description:
                        "This three-year program combines theoretical knowledge with practical skills, adhering to high academic standards and industry requirements while fostering strategic thinking, ethical values, and professional acumen.",
                    course: "BBA",
                    years: "3 years",
                    fees: "₹2,55,000",
                    slug: "online-bba",
                    short_slug: "international-finance",
                    bgImage: require('@/assets/home/degree/BBA-International-finance.webp'),
                    bgImgMob: require('@/assets/home/degree/BBA-International-finance.webp')
                },
                {
                    title: "BBA Program",
                    description:
                        "This three-year Online BBA provides a comprehensive foundation in core business principles, developing critical thinking and problem-solving skills through real-world applications.",
                    course: "BBA",
                    years: "3 years",
                    fees: "₹1,05,000",
                    slug: "online-bba",
                    short_slug: "online-bba-program",
                    bgImage: require('@/assets/home/degree/BBA.webp'),
                    bgImgMob: require('@/assets/home/degree/BBA.webp')
                },
                {
                    title: "BCA Program",
                    description:
                        "This three-year Online BCA degree provides comprehensive training in key IT areas, preparing students for diverse career paths in software development, database administration, and web design.",
                    course: "BCA",
                    years: "3 years",
                    fees: "₹1,05,000",
                    slug: "online-bca",
                    short_slug: "online-bca-program",
                    bgImage: require('@/assets/home/degree/BCA.webp'),
                    bgImgMob: require('@/assets/home/degree/BCA.webp')
                },
                {
                    title: "Political Science",
                    description:
                        "This three-year Online BA (Hons.) in Political Science offers comprehensive training in political theory, global affairs, and governance, preparing graduates for diverse career paths in policy analysis, research, and public and private sectors. ",
                    years: "3 years",
                    fees: "₹1,05,000",
                    course: "BA",
                    slug: "online-ba",
                    short_slug: "political-science",
                    bgImage: require('@/assets/home/degree/BA(Hons)-Political-Science.webp'),
                    bgImgMob: require('@/assets/home/degree/BA(Hons)-Political-Science.webp')
                },
            ],
            activeAccordions: {
                masters: 0,
                bachelors: 0    ,
            },
            isVisible: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll(); // Check the visibility on initial load
        this.setInitialActiveAccordion()
        
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        setInitialActiveAccordion() {
            // Add 'active' class to the initially active accordion content
            this.$nextTick(() => {
                const mastersItem = this.$el.querySelectorAll('.accordion-item')[this.activeAccordions.masters];
                const bachelorsItem = this.$el.querySelectorAll('.accordion-item')[this.activeAccordions.bachelors];
                
                if (mastersItem) {
                    mastersItem.querySelector('.accordion-content').classList.add('active');
                }
                if (bachelorsItem) {
                    bachelorsItem.querySelector('.accordion-content').classList.add('active');
                }
            });
        },
        toggleAccordion(index, type) {
            if (this.activeAccordions[type] === index) {
                this.activeAccordions[type] = null;
            } else {
                this.activeAccordions[type] = index;
            }
        },

        handleScroll() {
            const rect = this.$refs.heading1.getBoundingClientRect();
            const viewHeight =
                window.innerHeight || document.documentElement.clientHeight;
            if (rect.top <= viewHeight && rect.bottom >= 0) {
                this.isVisible = true;
            } else {
                this.isVisible = false;
            }
        },
       
    },
};
</script>

<style scoped>
.accordion-content {
    z-index: 10; /* Ensure it's above other elements */
}
/* .accordion-item {
    margin-bottom: 10px;
} */



.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.w-full {
    width: 100%;
}

.p-5 {
    padding: 20px;
}

.m-0 {
    margin: 0;
}

.textAnimation {
    margin: 0;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity 1.5s ease, transform 1.5s ease;
    transform: translateY(100%);
}

.textAnimation.visible {
    opacity: 1;
    transform: translateY(0);
}


.accordion-content {
    /* position: relative; */
    overflow: hidden;
    opacity: 1;
    transform: translateY(20px);
 
}
.accordion-content.active {
    opacity: 1;
    transform: translateY(-100%); 
}
.accordion-content.active,.accordion-content:hover {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}

.accordion-content img {
    transform: translateY(100%); 
    opacity: 1; 
    transition: opacity 2.5s ease, transform 2.5s ease; 
    
}

.accordion-content.active img {
    opacity: 1; /* Show the image */
    transform: translateY(0%); 
    color: black;
    
}
.accordion-content:hover img {
    opacity: 1;
    transform: translateY(0%);;
    transition: opacity 2.5s ease, transform 2.5s ease; 
}


</style>
