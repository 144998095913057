<template>
  <div class="px-5 lg:px-8">
    <div class="mx-auto max-w-7xl 2xl:max-w-screen-2xl my-10 lg:my-20">
      <div class="flex gap-5 flex-wrap justify-center">
        <div class="w-full lg:w-1/3">
          <img src="@/assets/home/placementsupport/online-degrees-shardaonline.webp" alt="" srcset=""
            class="w-full h-[320px] lg:w-[390px] lg:h-[390px]" />
        </div>
        <div>
          <div class="flex flex-col gap-4">
            <h1 class="text-[30px] lg:text-[60px] flex gap-4 font-normal">
              <span class="text-[#18479E]">Placement</span>
              <span class="text-[#EC027C]">Support</span>
            </h1>
            <p class="text-[16px] lg:text-[24px] lg:w-[600px] font-normal">
              Venturing into the professional landscape after your academic
              journey is a significant step. Here's how Sharda Online will help you:
            </p>
            <ul class="flex flex-col gap-3">
              <li class="flex gap-2 items-center text-[18px]">
                <img src="@/assets/home/placementsupport/check.svg" alt="" srcset="" class="w-[10px] h-[8px]" />
                Essential tools and support
              </li>
              <li class="flex gap-2 items-center text-[18px]">
                <img src="@/assets/home/placementsupport/check.svg" alt="" srcset="" class="w-[10px] h-[8px]" />
                Expert guidance
              </li>
              <li class="flex gap-2 items-center text-[18px]">
                <img src="@/assets/home/placementsupport/check.svg" alt="" srcset="" class="w-[10px] h-[8px]" />
                Resume building
              </li>
              <li class="flex gap-2 items-center text-[18px]">
                <img src="@/assets/home/placementsupport/check.svg" alt="" srcset="" class="w-[10px] h-[8px]" />
                Interview preparation
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlacementSupport",
};
</script>

<style></style>
