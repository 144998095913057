<template>
  <div class="W-full flex flex-justify-center my-20">
    <div
      class="max-w-7xl mx-auto flex justify-center flex-col text-center lg:gap[50px]"
    >
      <h1
        class="mt-4 text-[#18479E] text-4xl lg:text-6xl mb-4 font-normal hidden lg:block"
      >
        Explore the <span class="text-[#ec027c]">Sharda University</span> Life
      </h1>
      <div class="lg:hidden">
        <div class="mt-4 text-[#18479E] text-3xl lg:text-6xl font-normal">
          Explore the
        </div>
        <div class="mt-2 text-[#18479E] text-3xl lg:text-6xl font-normal">
          <span class="text-[#ec027c]">Sharda University</span> Life
        </div>
      </div>
      <div class="p-5">
        <iframe
          class="max-w-5xl rounded-lg"
          data-v-7f302a73=""
          src="https://www.sharda.ac.in/vtour/tour.html?startscene=0&amp;startactions=lookat(5.99,0.08,120,0,0);"
          width="100%"
          height="500"
          frameborder="0"
          style="border: 0px"
          allowfullscreen="false"
          aria-hidden="false"
          tabindex="0"
          alt="shardamap"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
